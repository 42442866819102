import { platform, PlatformType } from "../../../bud-lite-tv/src/lib";
import { parseMarkerPiano } from "../../datas/parser";
import { Plugin } from "../../datas/plugin";
import { Channel } from "../../models/channel";
import { Tile } from "../../pages/rootPage";
import { getButtonLabel } from "../../views/MEABannerView";
import { Didomi } from "../cmp/didomi";
import { getVisitorMode } from "../cmp/visitorMode";

// Piano Analytics

type PianoEventName = "page.display" | "publisher.impression" | "click.action" | "player.start";

const PIANO_PAGE_TYPE_NAMES = [
  "accueil",
  "connexion",
  "chaine",
  "directs",
  "region",
  "categorie",
  "unitaire",
  "evenement",
  "programme",
  "video",
  "collection",
  "recherche",
  "compte",
  "enfant",
  "mes_videos",
  "parametre",
  "liste", // for allSportsPage (hub_tag)
] as const;
export type PianoPageTypeName = typeof PIANO_PAGE_TYPE_NAMES[number];

export const parsePianoPageTypeEnum = (value: unknown) => {
  return PIANO_PAGE_TYPE_NAMES.find(element => element === value);
};

type PianoParams = {
  // Context: all pages & all events
  site: string;
  origin_page: string | undefined;
  origin_page_type: PianoPageTypeName | undefined;
  visitor_login_status: "true" | "false";
  user_id: string | undefined;
  app_version: string;
  // Specific params
  channel?: string;
  region?: string;
  highlight?: string;
  social_media?: string;
  content_type?: string;
  content_title?: string;
  content_status?: string;
  content_format?: string;
  content_diffusion_date?: string;
  content_id?: string;
  video_factory_id?: string;
  content_sponsored?: boolean;
  content_logged?: boolean;
  program_id?: string;
  program?: string;
  zone?: string;
  position?: string;
  season?: string;
  category?: string;
  sub_category?: string;
  login_status?: boolean;
  click?: string;
  feature?: string;
  error_message?: string;
  ise_keyword?: string;
  ise_page?: number;
  ise_click_rank?: number;
  ise_result?: string;
  consent_att?: string;
  login_stay_connected?: boolean;
  opt_in_newsletter?: boolean;
  break_ads_number_expected?: number;
  break_duration_expected?: number;
  break_ads_number?: number;
  break_duration?: number;
  player_version?: string;
  cmp_analytics?: "true" | "false";
  cmp_custom_content?: "true" | "false";
};

type PianoParamsWithPage = PianoParams & {
  page: string;
  page_type: PianoPageTypeName;
};

type PianoGDPR = "essential" | "optional" | "mandatory";
type PianoGDPRMode = "opt-in" | "essential" | "opt-out";

type PianoAnalytics = {
  setConfigurations: (configuration: { site: number; collectDomain: string }) => void;
  sendEvent: (eventName: PianoEventName, params: PianoParams) => void;
  consent: {
    setMode: (mode: PianoGDPRMode) => void;
    getMode: () => PianoGDPRMode;
  };
};

/**
 * Piano Analytics instance
 *
 * This variable can be undefined when PA is not yet initialized
 */
let pianoAnalytics: PianoAnalytics | undefined = undefined;
declare global {
  interface Window {
    pdl:
      | Partial<{
          /**
           * Activating the Piano Analaytics Consents feature
           * Reference: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#activating-the-consents-feature
           */
          requireConsent: boolean;
          consent_items: Partial<{
            PA: Partial<{
              /**
               * Allow customization of properties sent by Piano Analytics
               * according to user consent mode
               *
               * optional: opt-in
               * essential: exempt, opt-out
               * mandatory: exempt, opt-out, opt-in
               * Reference: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#manage-default-consent-modes
               */
              properties: Partial<Record<keyof PianoParams, PianoGDPR>>;
              /**
               * Allow customization of events sent by Piano Analytics
               *
               * Any custom events need to be added to this array to be able to be
               * sent by PA SDK.
               */
              events: Record<PianoEventName, PianoGDPR>;
            }>;
          }>;
        }>
      | undefined;
  }
}

const getPianoConfiguration = (): { site: number; server: string } => {
  // set defaultConf to lg preprod
  const defaultPianoConf = {
    site: 634556,
    server: "logs1238",
  };

  const PIANO_IDS: {
    prod: Record<PlatformType, { site: number; server: string }>;
    preprod: Record<PlatformType, { site: number; server: string }>;
  } = {
    prod: {
      [PlatformType.webos]: {
        site: 634557,
        server: defaultPianoConf.server,
      },
      [PlatformType.tizen]: {
        site: 634555,
        server: defaultPianoConf.server,
      },
      [PlatformType.orange]: {
        site: 639654,
        server: defaultPianoConf.server,
      },
      [PlatformType.hisense]: {
        site: 639853,
        server: defaultPianoConf.server,
      },
      [PlatformType.philips]: {
        site: 639852,
        server: defaultPianoConf.server,
      },
      [PlatformType.other]: defaultPianoConf,
    },
    preprod: {
      [PlatformType.webos]: {
        site: 634556,
        server: defaultPianoConf.server,
      },
      [PlatformType.tizen]: {
        site: 634554,
        server: defaultPianoConf.server,
      },
      [PlatformType.orange]: {
        site: 639734,
        server: defaultPianoConf.server,
      },
      [PlatformType.hisense]: {
        site: 639855,
        server: defaultPianoConf.server,
      },
      [PlatformType.philips]: {
        site: 639854,
        server: defaultPianoConf.server,
      },
      [PlatformType.other]: defaultPianoConf,
    },
  } as const;

  return PIANO_IDS[__BACKEND_TARGET__ === "prod" || __BACKEND_TARGET__ === "proxy" ? "prod" : "preprod"][platform.type];
};

const GDPRPianoProperty: Record<keyof PianoParamsWithPage | "*", PianoGDPR> = {
  site: "mandatory",
  page: "mandatory",
  page_type: "mandatory",
  origin_page: "mandatory",
  origin_page_type: "mandatory",
  visitor_login_status: "mandatory",
  app_version: "mandatory",
  user_id: "optional",
  channel: "mandatory",
  region: "mandatory",
  highlight: "mandatory",
  social_media: "mandatory",
  content_type: "mandatory",
  content_title: "mandatory",
  content_status: "mandatory",
  content_format: "mandatory",
  content_diffusion_date: "mandatory",
  content_id: "mandatory",
  video_factory_id: "mandatory",
  content_sponsored: "mandatory",
  content_logged: "mandatory",
  program_id: "mandatory",
  program: "mandatory",
  zone: "mandatory",
  position: "mandatory",
  season: "mandatory",
  category: "mandatory",
  sub_category: "mandatory",
  login_status: "mandatory",
  click: "mandatory",
  feature: "mandatory",
  error_message: "mandatory",
  ise_keyword: "mandatory",
  ise_page: "mandatory",
  ise_click_rank: "mandatory",
  ise_result: "mandatory",
  consent_att: "mandatory",
  login_stay_connected: "mandatory",
  opt_in_newsletter: "optional",
  break_ads_number_expected: "mandatory",
  break_duration_expected: "mandatory",
  break_ads_number: "mandatory",
  break_duration: "mandatory",
  player_version: "mandatory",
  cmp_analytics: "mandatory",
  cmp_custom_content: "mandatory",

  /**
   * `*` is set to mandatory to mark any custom field as mandatory.
   * By default, any field that has not been marked with a valid PianoGDPR
   * mode are not received on PA dashboard if the user did not accept
   * tracking on the Consent Management Page.
   */
  "*": "mandatory",
};

export const initializePianoAnalytics = () => {
  if (pianoAnalytics === undefined) {
    /**
     * window.pdl NEEDS to be imported BEFORE importing Piano Analytics.
     * Reference: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#pre-requisite
     */
    window.pdl = {
      ...window.pdl,
      /**
       * Enable controlling consent mode with .setMode method
       * Rerence: https://developers.atinternet-solutions.com/piano-analytics/data-collection/how-to-send-events/consent#pre-requisite
       */
      requireConsent: true,
      /**
       * Defining GDPR rules for Piano Analytics properties
       */
      consent_items: {
        PA: {
          properties: GDPRPianoProperty,
          events: {
            "page.display": "mandatory",
            "publisher.impression": "mandatory",
            "click.action": "mandatory",
            "player.start": "mandatory",
          },
        },
      },
    };

    /**
     * Importing Piano Analytics module.
     *
     * PA is imported using `require` keyword to avoid being imported
     * when this script is imported in other files, insuring that
     * window.pdl is defined BEFORE imported  PA.
     */
    const pianoAnalyticsModule = require("piano-analytics-js");
    pianoAnalytics = pianoAnalyticsModule.pianoAnalytics as PianoAnalytics;

    const pianoConfiguration = getPianoConfiguration();

    pianoAnalytics.setConfigurations({
      site: pianoConfiguration.site,
      collectDomain: `https://${pianoConfiguration.server}.xiti.com`,
    });
  }
};

// Reload Privacy Mode
/**
 * PA is the only vendor that is using Visitor Mode (CMP => More Info => Checkbox (opposition au dépot du traceur))
 * - VisitorMode = "disabled" => opt-out
 * - VisitorMode = "enabled" & vendor enabled => opt-in
 * - VisitorMode = "enabled" & vendor disabled => essential
 */
const setPianoPrivacyMode = () => {
  if (pianoAnalytics !== undefined) {
    const visitorMode = getVisitorMode().value;
    switch (visitorMode) {
      case "disabled": {
        pianoAnalytics.consent.setMode("opt-out");
        break;
      }
      case "enabled": {
        if (Didomi.isVendorAllowedToTrack("atinterne-D22mcTNf")) {
          pianoAnalytics.consent.setMode("opt-in");
        } else {
          pianoAnalytics.consent.setMode("essential");
        }
        break;
      }
    }
  } else {
    Log.app.error("Failed to set Piano Analytics consent mode since it is not yet initialized.");
  }
};

/**
 * Keep track of the previous "origin" page as defined on FTV's "Plan de marquage" file
 *
 * Previous `page` & `pageType` will be added as `origin_page` & `origin_page_type` dynamically
 * on every events
 *
 * We are forcing default first page to `accueil`.
 */
const originPageParams: { page: string | undefined; pageType: PianoPageTypeName | undefined } = {
  page: "accueil",
  pageType: "accueil",
};

type IPianoMap = {
  /**
   * page.display is the only event that will update
   */
  "page.display": PianoParamsWithPage;
  "click.action": PianoParams;
  "publisher.impression": PianoParams;
  "player.start": PianoParams;
};

export const sendPianoAnalytic = <T extends PianoEventName>(
  /**
   * Piano Event
   */
  eventName: T,
  /**
   * Regular Piano Parameters as defined on "Plan de marquage".
   *
   * Omitted values are added dynamically on every event
   */
  params: Omit<
    IPianoMap[T],
    "user_id" | "visitor_login_status" | "origin_page" | "origin_page_type" | "site" | "app_version"
  >,
  /**
   * Any additionnal params to pass with the event
   */
  additionnalParams: Record<string, string | boolean | number>
) => {
  if (pianoAnalytics !== undefined) {
    // Omitted Piano Params
    const dynamicsParams = {
      // Site
      site: `${getPianoConfiguration().site}`,
      // User
      visitor_login_status: `${Plugin.getInstance().user.isActive()}` as const,
      user_id: Plugin.getInstance().user?.infos?.publicId,
      // Using previously saved origin page
      origin_page: originPageParams.page,
      origin_page_type: originPageParams.pageType,
      // App version
      app_version: __APP_VERSION__,
    };

    let pianoAnalyticsParams: PianoParamsWithPage | null = null;
    if (eventName === "page.display") {
      /**
       * page.display is the only event that can update page & page_type params.
       *
       * IMPORTANT:
       * We need to mark params as "IPianoMap["page.display"]" to have access to .page & .pageType params
       * This is a limitation from current version of Typescript (4.2.3)
       * `eventName` is not being infered as litteral constant after if statement.
       */
      const paramsPageDisplay = params as IPianoMap["page.display"];

      // Updating originPageParams
      originPageParams.page = paramsPageDisplay.page;
      originPageParams.pageType = paramsPageDisplay.page_type;

      // Adding ommitted params
      pianoAnalyticsParams = {
        ...paramsPageDisplay,
        ...dynamicsParams,
      };
    } else {
      /**
       * We are not in `page.dislay` event, we did not pass `page` / `pageType` params
       * We should add back saved origin page params as current page
       */

      if (originPageParams.page !== undefined && originPageParams.pageType) {
        // Adding ommitted params + current page
        pianoAnalyticsParams = {
          ...params,
          page: originPageParams.page,
          page_type: originPageParams.pageType,
          ...dynamicsParams,
        };
      }
    }

    if (pianoAnalyticsParams !== null) {
      /**
       * Send event with piano analytics basic event +
       * any additionnal params
       */
      setPianoPrivacyMode();
      pianoAnalytics.sendEvent(eventName, {
        // Piano Analytics Params + Dynamic Params + Current Page
        ...pianoAnalyticsParams,
        // Additionnal Params added to the request
        ...additionnalParams,
      });
    } else {
      Log.app.error("Failed to send Piano Analytics. Is any event sent before any `page.display` event ?");
    }
  } else {
    Log.app.error("Failed to send Piano Analytics since it is not yet initialized.");
  }
};

export const getAdditionalProperties = (receivedProperties: unknown): Record<string, string> => {
  const properties: Record<string, string> = {};

  if (typeof receivedProperties === "object" && receivedProperties !== null) {
    for (const [key, value] of Object.entries(receivedProperties)) {
      if (typeof value === "string" || typeof value === "boolean" || typeof value === "number") {
        properties[key] = `${value}`;
      }
    }
  }

  return properties;
};

export const sendClickPianoEvent = (item: Tile, position?: number) => {
  const currentPage = originPageParams;

  const markerPiano = parseMarkerPiano(item.extras);
  if (markerPiano === undefined) {
    Log.analytics.error("Failed to parse Marker Piano");
    return;
  }

  const additionalProperties = markerPiano.additional_properties;
  const contextualProperties = markerPiano.contextual_properties;

  let properties: Record<string, string | number | boolean> = {
    ...additionalProperties,
  };

  if (!("content_type" in properties) && contextualProperties && typeof contextualProperties.page_type === "string") {
    properties["content_type"] = contextualProperties.page_type;
  }

  if (!("content_title" in properties) && contextualProperties && typeof contextualProperties.page === "string") {
    properties["content_title"] = contextualProperties.page;
  }

  const currentPosition = position != undefined ? position + 1 : position;

  const isMEACTAButton = position === -1 ? true : false;

  if (isMEACTAButton) {
    const buttonLabel = getButtonLabel(item);

    properties = {
      ...properties,
      feature: "accueil_cta_" + buttonLabel,
      click: buttonLabel,
      zone: "mea_immersive",
    };
  } else {
    switch (currentPage.pageType) {
      case "accueil":
        if (!(item instanceof Channel)) {
          properties = {
            ...properties,
            feature: "bloc_" + contextualProperties?.page,
            click: "vignette",
            zone: "playlist",
          };

          if (currentPosition !== undefined) {
            properties["position"] = currentPosition;
          }
        }
        break;
      case "unitaire":
        properties = {
          ...properties,
          feature: "unitaire_cta_regarder_maintenant",
          click: "regarder_maintenant",
        };
        break;
      case "evenement":
        properties = {
          ...properties,
          feature: "bloc_" + contextualProperties?.page,
          click: "vignette",
        };

        if (currentPosition !== undefined) {
          properties["position"] = currentPosition;
        }
        break;
      case "categorie":
        if (currentPage.page == "accueil_categorie") {
          properties = {
            ...properties,
            feature: "bloc_" + contextualProperties?.page,
            click: "vignette",
          };

          if (item.itemCollection?.type !== undefined) {
            properties["zone"] = item.itemCollection.type;
          }

          if (currentPosition !== undefined) {
            properties["position"] = currentPosition;
          }
        } else {
          properties = {
            ...properties,
            feature: "bloc_" + contextualProperties?.page,
            click: "vignette",
          };

          if (item.itemCollection?.type !== undefined) {
            properties["zone"] = item.itemCollection.type;
          }

          if (currentPosition !== undefined) {
            properties["position"] = currentPosition;
          }
        }
        break;
      case "programme":
      case "collection":
      case "directs":
      case "chaine":
      case "region":
      case "recherche":
      case "mes_videos":
      case "enfant":
      case "liste":
        properties = {
          ...properties,
          feature: "bloc_" + contextualProperties?.page,
          click: "vignette",
        };

        if (item.itemCollection?.type !== undefined) {
          properties["zone"] = item.itemCollection.type;
        }

        if (currentPosition !== undefined) {
          properties["position"] = currentPosition;
        }
        break;
      default:
        Log.analytics.error(`No page of "${currentPage.pageType}" type found`);
    }
  }

  if (Object.keys(properties).length !== 0) {
    sendPianoAnalytic("click.action", {}, properties);
  }
};
