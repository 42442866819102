"use strict";
// this is the first thing to call in main. It defines the polyfills depending on the platform type,
// and does platform specific work (key registering for example)
require("~ui-lib/platform");
// the app needs string.normalize for analytics
require("unorm");

// then we need log to be defined ASAP
require("./log");

import "./main.scss";
import "@procot/webostv/webOSTV-dev";

import { logKeyHandler } from "@dotscreen/log-tv";

import { Storage } from "~libs/storage";
import { createNavigationStack, mapKeyboardEvent, platform, PlatformType } from "~ui-lib";

// it's *CRITICAL* this is created before any other list, as it's defining if a list supports mouse or not
// the rootMenu uses list, and if it's imported before this stack is created it won't be created as mouse-compatible
export const navigationStack = createNavigationStack({
  rootElement: document.getElementById("navigationStack")!,
  mouseSupport: true,
  wheelThrottle: platform.type == PlatformType.other ? 500 : undefined,
  onExit: () => {
    console.warn("exit! show menu first?");
  },
});

import { PopupPage } from "~pages/popup/popupPage";

import { Config } from "./config";
import { Plugin } from "./datas/plugin";
import { Category } from "./models/category";
import { Channel } from "./models/channel";
import { Collection } from "./models/collection";
import { Event } from "./models/event";
import { Media } from "./models/media";
import { Metadata } from "./models/metadata";
import { Program } from "./models/program";
import { Unit } from "./models/unit";
import { USER_ID_STORAGE_KEY } from "./models/user";
import { CategoryDetailTab } from "./pages/category/categoryDetailTab";
import { ChannelTab } from "./pages/channel/channelTab";
import { ConsentManagementTab } from "./pages/cmp/consentManagement/consentManagementPage";
import { CollectionTab } from "./pages/collection/collectionTab";
import { EventTab } from "./pages/event/eventTab";
import { HomeTab } from "./pages/home/homeTab";
import { ProgramTab } from "./pages/program/programTab";
import { onSelectTile, pushConnexionPage, pushPlayerPage, pushTabWithMenu } from "./pages/rootPage";
import { UnitTab } from "./pages/unit/unitTab";
import { initializePianoAnalytics } from "./tools/analytics/piano";
import { Didomi } from "./tools/cmp/didomi";
import { startMultitaskingManager } from "./tools/multitasking";
import { MainMenuItemSlug } from "./views/mainMenu/itemMenu";

// the app entry point. Nothing should be done / created / instantiated before that is called
function startApp() {
  if (Config.enabledFeatures.lowDevice) {
    document.body.classList.add("lowDevice");
  }

  document.addEventListener(
    "keydown",
    ev => {
      if (platform.type === PlatformType.philips) {
        ev.preventDefault(); // by default Philips TVs close the app on back pressed regardless of the current page.
      }
      const key = mapKeyboardEvent(ev);
      // if log handles key we don't want the app to process the key
      if (logKeyHandler(key, "keydown")) return;
      navigationStack.keyHandler(key);
    },
    false
  );

  /**
   * Samsung: Implementing Public Preview Deep Links
   */
  if (platform.type == PlatformType.tizen) {
    /**
     * To receive "action_data" information when the application is already running,
     * add an event listener for the appcontrol event to the onload property
     */
    Log.app.log("[deepLinkTizen appcontrol] put listener here !! ");

    window.addEventListener("appcontrol", () => {
      deepLinkTizen();
    });
  }

  if (!__IS_RELEASE__) {
    // debug version
    document.querySelector("#versionApp")!.innerHTML = `${__APP_VERSION__}-${__APP_HASH__}-${
      __IS_PRODUCTION__ ? "prod" : "dev"
    }`;
    // // debug counter
    // showDebugStats("debugStats");
  }

  startMultitaskingManager();

  /**
   * Logged in user with valid consent -> HomeTab
   * Logged in user without consent -> ConsentManagementTab -> Home Page
   * User with valid consent -> Log in Page
   * User without consent -> ConsentManagementTab -> Log in Page
   *
   *
   * should be:
   * Log in user / user without consent -> ConsentManagementTab -> HomeTab / Log In Page
   * Log in user / user with consent -> HomeTab / Log In Page
   *
   * Meaning that we should:
   * 1. Log in user
   * 2. Check Didomi
   * 3. Redirect to valid page
   */

  void (async () => {
    // 1. Reconnect user if needed
    if (Storage.getItem(USER_ID_STORAGE_KEY)) {
      try {
        await Plugin.getInstance().fetchReconnect().toPromise();
      } catch (error) {
        Log.app.error(error);

        // Failed to reconnect user, disconnecting user
        Plugin.getInstance().user.deconnect();
      }
    }

    // 2. Initialize didomi for current user
    await Didomi.getUserConsent();

    // 3. Initialize PA
    initializePianoAnalytics();

    // 4. Check if user should be prompted CMP
    if (Didomi.shouldUserBePromptedCmp()) {
      navigationStack.pushPage(new ConsentManagementTab());
    } else {
      const user = Plugin.getInstance().user;
      if (user.isActive()) {
        pushTabWithMenu(new HomeTab(), "homePage", MainMenuItemSlug.home);
      } else {
        pushConnexionPage();
      }
    }
  })();
}

export function checkDeepLink() {
  switch (platform.type) {
    case PlatformType.tizen:
      // the deeplink value is supposed to be handled with Tizen listener where we call deepLinkTizen()
      deepLinkTizen();
      break;
    case PlatformType.webos:
      deepLinkLG();
      break;
    default:
      loadDeepLink();
      break;
  }
}

/**
 * Example:
 * OPEN PAGE
 * #page/program/france-2_l-amie-prodigieuse => open a page program with ID = france-2_l-amie-prodigieuse
 * #page/program/spectacles-et-culture_emissions-culturelles_culturebox-l-emission
 * #page/categorie/series-et-fictions
 * #page/collection/1801255
 * #page/collection/2715013
 * #page/collection/2223891
 * #page/event/sport_les-jeux-olympiques => open a page unit with ID = sport_les-jeux-olympiques
 * #page/channel/france-2
 * #page/channel/slash
 * #page/unit/2587231 => open a page unit with ID = 2587231
 * #page/unit/2702889
 * #page/unit/2702893
 *
 * OPEN PLAYER
 * #player/unit/2587231 => open a page unit with ID = 2587231
 */

function loadDeepLink(deeplink?: string) {
  Log.app.log("[deepLinkApp] deeplink?: " + deeplink);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let deepLinkPathName: string | any[] = [];
  if (deeplink) {
    deeplink = deeplink.replace("#", "");
    deepLinkPathName = deeplink.split("/");
  } else {
    deepLinkPathName = location.hash.substr(1, location.hash.length).split("/");
    history.replaceState("", "FranceTv", `${location.pathname}`);
  }

  if (deepLinkPathName.length > 1) {
    const page = deepLinkPathName[0];
    const type = deepLinkPathName[1];
    const url_id = deepLinkPathName[2];

    // open detail page (menu on left)
    if (page == "page") {
      switch (type) {
        case "program":
          pushTabWithMenu(
            new ProgramTab(new Program("", "", "", "", [], { program_path: url_id }, false)),
            "ProgramPage"
          );
          break;
        case "collection":
          pushTabWithMenu(new CollectionTab(new Collection(url_id, "", "", "", [], {}, false)), "CollectionPage");
          break;
        case "categorie":
          pushTabWithMenu(
            new CategoryDetailTab(new Category("", "", "", "", [], { url_complete: url_id })),
            "CategoryPage"
          );
          break;
        case "unit":
        case "extrait":
        case "integrale":
          pushTabWithMenu(
            new UnitTab(
              new Unit(
                url_id,
                "",
                "",
                "",
                [],
                new Metadata({ is_live: false }, 0),
                {},
                new Media({}, null, null, null),
                true,
                false
              )
            ),
            "UnitPage"
          );
          break;
        case "event":
          pushTabWithMenu(new EventTab(new Event("", "", "", "", [], { url_complete: url_id }, false)), "EventPage");
          break;
        case "channel":
          pushTabWithMenu(new ChannelTab(new Channel("", "", "", "", [], { channel_url: url_id })), "ChannelPage");
          break;
        default:
          break;
      }
    }
    // open player page (fullscreen)
    else if (page == "player") {
      const unit = new Unit(
        url_id,
        "",
        "",
        "",
        [],
        new Metadata({}, 0),
        {},
        new Media({}, null, null, null),
        true,
        false
      );
      if (type === "channel") {
        Plugin.getInstance()
          .fetchCurrentDirect(url_id)
          .toPromise()
          .then(json => {
            onSelectTile(json, 0);
          })
          .catch(e => {
            Log.app.error("FetchCurrentDirect error :", e);
          });
      } else {
        Plugin.getInstance()
          .fetchDetailed(unit)
          .subscribe(
            value => {
              // Here use it to create the UI
              //Log.api.log("[DEEPLINK TO PLAYER] Next !", value);
              const newUnit = value[0];
              pushPlayerPage(
                new Unit(
                  newUnit.id,
                  newUnit.type,
                  newUnit.title,
                  newUnit.summary,
                  newUnit.artworks,
                  newUnit.metadata,
                  newUnit.extras,
                  newUnit.media,
                  newUnit.login,
                  newUnit.sponsored
                )
              );
            },
            error => {
              // Here use it to trigger and display an error
              Log.api.error("[DEEPLINK TO PLAYER] Error !", error);
            },
            () => {}
          );
      }
    }
  }
}

/** from RSS data, need to be provided as below, where $CONTENTID is the format deeplink
 * {
    "id": "com.yourdomain.app.appname",
    ...
    "deeplinkingParams" : "{ \"contentTarget\": \"$CONTENTID\" }"
}
 */
function deepLinkLG() {
  Log.app.log("[deepLinkLG] ????? ");

  //Get launchParams when your app is launched.
  window.webOSDev = webOSDev;
  const launchParams = webOSDev.launchParams();

  if (launchParams) {
    if (launchParams.contentTarget) {
      // If the contentTarget has a data in Target URL format, use it as a location.
      loadDeepLink(launchParams.contentTarget.toString());
    } else {
      // If contentTarget has no data, handle the exception.
      // At the moment, nothing happens by default
    }
  } else {
    // If your app do not support deep linking, handle the exception.
    // At the moment, nothing happens by default
  }
}

/**
 * When a tile is clicked, its associated "action_data" value is converted to an ApplicationControlData
 * object with the "PAYLOAD" key
 */
function deepLinkTizen() {
  Log.app.log("[deepLinkTizen] ????? ");

  const requestedAppControl = tizen.application.getCurrentApplication().getRequestedAppControl();

  if (requestedAppControl && requestedAppControl.appControl && requestedAppControl.appControl.data) {
    const payloadData = getPayloadData(requestedAppControl.appControl.data);

    Log.app.log("[deepLinkTizen] requestedAppControl: " + JSON.stringify(requestedAppControl));

    if (payloadData) {
      Log.app.log("[AppControl] PAYLOAD object found - app launched from preview.");
      Log.app.log("[deepLinkTizen] appControlData deeplink : " + payloadData.contentId.toString());
      loadDeepLink(payloadData.contentId.toString());
    } else {
      Log.app.log("[AppControl] No PAYLOAD object found - app launched normally.");
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPayloadData(data: any) {
  return data.filter(isPayload).map(decodeData)[0];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isPayload(data: any) {
  return data.key === "PAYLOAD";
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function decodeData(data: any) {
  const values = JSON.parse(data.value[0]).values;
  const decodedValues = decodeURIComponent(values);

  return JSON.parse(decodedValues);
}

export function exitApp(): boolean {
  Log.app.log("QUIT THE APP");
  navigationStack.pushPage(
    new PopupPage({
      title: "Souhaitez-vous quitter l'appli ?",
      description: "",
      button1: {
        label: "oui",
        action: () => {
          platform.exit();
        },
      },
      button2: {
        label: "non",
      },
    })
  );

  return false;
}

window.onload = startApp;
